import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"

// sections
import MainSection from "./sections/zul-mais/main"
import ParkingSection from "./sections/zul-mais/parking"
import TagSection from "./sections/zul-mais/tag"
import ValueSection from "./sections/zul-mais/value"
import FinesSection from "./sections/zul-mais/fines"
import FuelSection from "./sections/zul-mais/fuel"
import TrafficRestrictionSection from "./sections/zul-mais/traffic-restriction"
import MallParkingSection from "./sections/zul-mais/mall-parking"
import AsureSection from "./sections/zul-mais/asure"
import DealersSection from "./sections/zul-mais/dealers"
import InstallSection from "./sections/zul-mais/install"
import PaymentsSection from "./sections/zul-mais/payments"

const ZulMaisPage = () => (
  <Layout contentClass="page page-products">
    <SEO title="Zul Mais" />

    <Header siteTitle={`Zul Digital`} menu={true} />

    <MainSection />
    <PaymentsSection />
    <ParkingSection />
    <TagSection />
    <ValueSection />
    <FinesSection />
    <FuelSection />
    <TrafficRestrictionSection />
    <MallParkingSection />
    <AsureSection />
    <DealersSection />
    <InstallSection />

    <Footer />
  </Layout>
)

export default ZulMaisPage
