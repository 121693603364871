import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const FinesSection = () => {
  const data = useStaticQuery(graphql`
    query {
      finesMockupDesktop: file(
        relativePath: { eq: "products/fines-mockup-desktop.png" }
      ) {
        publicURL
      }
      finesMockup: file(relativePath: { eq: "products/fines-mockup.png" }) {
        publicURL
      }
    }
  `)

  return (
    <section className="section section-fines section__right-chamfer">
      <div className="container">
        <h2 className="title has-text-white">
          Multas <br className="is-hidden-desktop" />e tributos
        </h2>
        <p className="subtitle is-hidden-desktop">
          Parcele tudo, antes ou depois do vencimento, em até 12x.
        </p>

        <div className="is-hidden-mobile">
          <div className="columns">
            <div className="column is-half">
              <p className="subtitle">
                Parcele tudo, antes ou depois do vencimento, em até 12x.
              </p>
            </div>
          </div>
          <div className="columns">
            <div className="column is-one-third">
              <p className="subtitle">
                Consulte e pague as multas, <br />
                o IPVA e o licenciamento <br />
                do seu carro em poucos segundos.
              </p>
            </div>
            <div className="column is-one-third">
              <p className="subtitle">
                Escolha o melhor meio <br />
                de pagamento e deslize <br />o dedo para pagar.
              </p>
            </div>
          </div>
        </div>
        <div className="images">
          <img
            className="fines-mockup"
            srcset={data.finesMockupDesktop.publicURL}
            src={data.finesMockup.publicURL}
            alt=""
          />
        </div>
      </div>
    </section>
  )
}

export default FinesSection
