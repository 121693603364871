import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const ParkingSection = () => {
  const data = useStaticQuery(graphql`
    query {
      boxZonaazul: file(relativePath: { eq: "products/box-zona-azul.svg" }) {
        publicURL
      }
      parkingCar: file(relativePath: { eq: "products/parking-car.png" }) {
        publicURL
      }
    }
  `)

  return (
    <section className="section section-parking section__left-chamfer">
      <div className="container">
        <h2 className="title has-text-white">
          Estacionamento <br />
          Rotativo
        </h2>
        <div className="images">
          <img
            className="box-zona-azul"
            src={data.boxZonaazul.publicURL}
            alt=""
            />
          <img
            className="parking-car"
            src={data.parkingCar.publicURL}
            alt=""
          />
        </div>
        <p className="subtitle is-hidden-desktop">
          A melhor experiência de compra e ativação de estacionamento rotativo
          no Brasil.
        </p>
        <div className="is-hidden-mobile">
          <div className="columns">
            <div className="column is-one-third">
              <p className="subtitle">
                A melhor experiência de compra e ativação de estacionamento
                rotativo no Brasil.
              </p>
            </div>
            <div className="column is-one-third">
              <p className="subtitle">
                Pague como quiser e ative o estacionamento em poucos segundos.
              </p>
            </div>
          </div>
        </div>
        <div className="is-clearfix">
          <Link to="/estacionamento-rotativo" className="button know-more">
            Saiba mais
            <span className="icon is-small">
              <i className="zul-icon-arrowr"></i>
            </span>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default ParkingSection
