import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const InstallSection = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.svg" }) {
        publicURL
      }
      appStoreIcon: file(relativePath: { eq: "icons/app-store.svg" }) {
        publicURL
      }
      googlePlayIcon: file(relativePath: { eq: "icons/google-play.svg" }) {
        publicURL
      }
      appStoreDesktop: file(
        relativePath: { eq: "products/app-store-desktop.svg" }
      ) {
        publicURL
      }
      googlePlayDesktop: file(
        relativePath: { eq: "products/google-play-desktop.svg" }
      ) {
        publicURL
      }
      installMockup: file(relativePath: { eq: "products/install-mockup.png" }) {
        publicURL
      }
      installCar: file(relativePath: { eq: "products/install-car.png" }) {
        publicURL
      }
    }
  `)

  return (
    <section className="section section-install section__left-chamfer">
      <div className="container">
        <h2 className="title">
          <span className="has-text-white">Tudo em</span> <br />
          <span className="has-text-blue">um só app.</span>
        </h2>
        <div className="is-clearfix">
          <div className="logo is-pulled-left">
            <img src={data.logo.publicURL} alt="" />
          </div>
          <div className="logo-cta is-pulled-left">
            <span className=" has-text-white">
              Seu carro <br />
              mais completo
            </span>
          </div>
        </div>
        <br className="is-clearfix" />
        <div className="download-links is-clearfix">
          <p className="title has-text-white">Disponível nas lojas:</p>

          <p className="has-text-centered">
            <a
              href="https://apps.apple.com/br/app/zul-zona-azul-digital-cet-sp/id1188772766"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="is-hidden-desktop"
                src={data.appStoreIcon.publicURL}
                width="149"
                height="41"
                alt="Apple App Store"
              />

              <img
                className="is-hidden-mobile"
                src={data.appStoreDesktop.publicURL}
                width="160"
                height="48"
                alt="Apple App Store"
              />
            </a>
          </p>
          <p className="has-text-centered">
            <a
              href="https://play.google.com/store/apps/details?id=br.com.zuldigital"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="is-hidden-desktop"
                src={data.googlePlayIcon.publicURL}
                width="149"
                height="41"
                alt="Google Play"
              />

              <img
                className="is-hidden-mobile"
                src={data.googlePlayDesktop.publicURL}
                width="160"
                height="48"
                alt="Apple App Store"
              />
            </a>
          </p>
        </div>

        <div className="images is-hidden-mobile">
          <img
            className="install-mockup"
            src={data.installMockup.publicURL}
            alt=""
          />
          <img
            className="install-car"
            src={data.installCar.publicURL}
            alt=""
          />
        </div>
      </div>
    </section>
  )
}

export default InstallSection
