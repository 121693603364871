import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const ValueSection = () => {
  const data = useStaticQuery(graphql`
    query {
      graphZulPlus: file(relativePath: { eq: "products/graph-zul-plus.svg" }) {
        publicURL
      }
      valueCar: file(relativePath: { eq: "products/value-car.png" }) {
        publicURL
      }
      valueMockup: file(relativePath: { eq: "products/value-mockup.png" }) {
        publicURL
      }
      value: file(relativePath: { eq: "products/value.png" }) {
        publicURL
      }
      valueDesktop: file(relativePath: { eq: "products/value-desktop.png" }) {
        publicURL
      }
    }
  `)

  return (
    <section className="section section-value section__left-chamfer">
      <div className="container">
        <h2 className="title has-text-white">Valor de mercado</h2>
        <p className="subtitle is-hidden-desktop">
          Consulte o valor de mercado do seu veículo e de outros modelos,
          fazendo simulações de compra e venda.
        </p>

        <div className="is-hidden-mobile">
          <div className="columns">
            <div className="column is-two-third">
              <p className="subtitle">
                Vai revender ou quer comprar <br />
                um carro?
              </p>
              <p className="subtitle">
                Acesse o app e descubra o valor de mercado <br/>
                de todos os modelos. Você ainda pode simular a compra, <br/>
                venda ou troca com o seu usado.
              </p>
            </div>
          </div>
        </div>
        <div className="images">
          <img
            className="value-graph"
            srcset={`${data.valueDesktop.publicURL} 1024w"`}
            src={data.value.publicURL}
            alt=""
          />
          <img
            className="value-mockup is-hidden-mobile"
            src={data.valueMockup.publicURL}
            alt=""
          />
          <img
            className="value-car"
            src={data.valueCar.publicURL}
            alt=""
          />
          <img
            className="value-zul-plus"
            src={data.graphZulPlus.publicURL}
            alt=""
          />
        </div>
      </div>
    </section>
  )
}

export default ValueSection
